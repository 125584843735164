<template>
  <v-container>
    <v-row class="text-left">
      <v-col class="mb-4">
        <v-card>
          <v-img :src="card.src" height="500px" width="500px">
          </v-img>
          <v-card-title>Title: {{card.title}}</v-card-title>
          <v-card-subtitle>Artist: {{card.artist}}</v-card-subtitle>
          <v-card-text>
           <v-icon>$vuetify.icons.osblue</v-icon> <a href="https://opensea.io/assets/0x6d4530149e5b4483d2f7e60449c02570531a0751/24764">OpenSea Link</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ArtCard',
    components: {
    },
    data: () => ({
      card: {
        src: "https://lh3.googleusercontent.com/s_HZfWpCXFuKqBYXOb-3lxtXZuhiD7MwfmRa366t5KP6NncnWqKcwGBTSOKCO9-SILRtuqDFFuuo2DH8ZHAVOKD8Vl05xCBEQJtGeQ=s0",
        title: "The Initial Visit",
        artist: "Your Favorite Potato",
      },
    }),
  }
</script>
