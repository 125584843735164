<template>
  <div>
    <Intro />
    <ArtCard/>
  </div>
</template>

<script>
  import Intro from '../components/Intro'
  import ArtCard from '../components/ArtCard'

  export default {
    name: 'Home',

    components: {
      Intro,
      ArtCard,
    },
  }
</script>
