import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA03bf095hdn5Mw-w38RtCOJu3eSmMwG-g",
  authDomain: "nftartgallery-app.firebaseapp.com",
  projectId: "nftartgallery-app",
  storageBucket: "nftartgallery-app.appspot.com",
  messagingSenderId: "1079421752679",
  appId: "1:1079421752679:web:04388bd47ca45d87380bc2",
  measurementId: "G-81ZF4NMHFL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

window['ga-disable-' + firebaseConfig.measurementId] = true;

Vue.prototype.$firebase = app
Vue.prototype.$analytics = analytics

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
