<template>
  <v-footer padless>
    <vue-cookie-accept-decline elementId="nftagcc" position="bottom"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline"
    >
    </vue-cookie-accept-decline>
    <v-card text width="100%">
      <v-card-text x-small >
      <v-btn x-small to="/terms" text>Terms</v-btn>
      <v-btn x-small to="/privacy" text>Privacy Policy</v-btn>

      <v-btn icon href="https://twitter.com/NFTArtGalleryA"><v-icon color="primary">mdi-twitter</v-icon></v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

export default {
  name: 'Footer',
  components: {
    VueCookieAcceptDecline,
  },
  methods: {
    cookieClickedAccept: function() {
      console.log('accept', this.$firebase.options.measurementId)
      window['ga-disable-' + this.$firebase.options.measurementId] = false
    },
    cookieClickedDecline: function() {
      console.log('decline')
      window['ga-disable-' + this.$firebase.options.measurementId] = true;
    },
  },
}
</script>
