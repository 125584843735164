import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import OSBlue from '@/components/OSBlue'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
     osblue: {
      component: OSBlue,
     },
    }
  },
});
